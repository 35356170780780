@font-face {
  font-family: Montserrat;
  src: url(/public/fonts/Montserrat-Regular.woff) format("truetype");
}
@font-face {
  font-family: Montserrat-Black;
  src: url(/public/fonts/Montserrat-Black.woff) format("truetype");
}
@font-face {
  font-family: Montserrat-Medium;
  src: url(/public/fonts/Montserrat-Medium.woff) format("truetype");
}

.banner-predictions {
  padding-left: 5px;
  margin-left: 13rem;
  margin-top: -30rem;
  width: 90%;
}
.swifty-predictions-img,
.who-we-are-img {
  width: 90%;
}

.predictions-text {
  height: 17.51px;
  margin-left: 13rem;
  padding-left: 5px;
}
.banner-text {
  height: 50px;
  margin-left: 13rem;
  padding-top: 35px;
  padding-left: 5px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  display: flex;
  align-items: center;
  color: #ffffff;
}
.who-we-are-text,
.who-we-are-title {
  display: flex;
  color: white;
  align-items: center;
  text-align: right;
}
.who-we-are-text {
  font-family: Montserrat-Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
}
.who-we-are-title {
  justify-content: end;
  font-family: Montserrat-Bold;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
}
.swifty-predictions-title {
  justify-content: start;
  font-family: Montserrat-Bold;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  color: white;
}
.banner-text,
.predictions-text {
  padding-left: 5px !important;
}
@media only screen and (max-width: 1200px) {
  .who-we-are-img {
    width: 95%;
  }
  .swifty-predictions-img {
    width: 90%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .col-md-6.banner-content {
    margin-top: 5rem;
    margin-left: -3rem;
  }
  .banner-predictions,
  .banner-text {
    width: 100%;
  }
}
@media only screen and (max-width: 768px) {
  .swifty-predictions-text {
    width: 90%;
  }
  .who-we-are-img {
    width: 75%;
    margin: auto;
  }
  .swifty-predictions-img {
    width: 75%;
    margin: auto;
  }
  .banner-predictions {
    max-width: 95%;
  }
}
@media only screen and (max-width: 992px) {
  .col-md-6.banner-content {
    margin-top: -5rem;
  }
  .banner-predictions,
  .predictions-text,
  .banner-text {
    margin-left: unset !important;
    padding-left: 0;
  }

  .predictions-text {
    max-width: 90%;
  }
  .banner-text {
    max-width: 100%;
    font-size: 17px;
  }
  .banner-predictions {
    margin-top: -5rem;
  }
  .who-we-are-text {
    display: flex;
    line-height: 17px;
    align-items: center;
    text-align: justify;
  }
  .who-we-are-title {
    font-size: 24px;
    justify-content: start;
  }
  .swifty-predictions-title {
    font-size: 24px;
    line-height: 30px;
    color: white;
  }
  .swifty-predictions-text {
    font-family: "Montserrat-Medium";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    text-align: justify;
  }
  #who-we-are-content,
  #swifty-predictions-content {
    margin: unset;
  }
  .highlights-list {
    margin-left: 1.5rem;
  }
  #highlights {
    width: 80%;
    text-align: center;
  }
  .cookie-text {
    margin-left: 5px;
  }

  .CookieConsent {
    justify-content: center !important;
  }

  .container-xxl.home-slider {
    margin-top: -5rem;
  }

  #first-row,
  #second-row {
    margin-bottom: -10rem;
  }
}
.highlights-list {
  color: white;
  font-family: "Montserrat-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin-top: 2rem;
  max-width: 200px;
}
.swifty-predictions-text {
  font-family: "Montserrat-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  color: white;
  align-items: center;
  text-align: left;
}

.highlights-img {
  z-index: -1;
  max-width: 329px;
  display: flex;
  justify-content: center;
}
.container-xxl {
  max-width: unset;
  padding-right: 0;
  padding-left: 0;
  overflow-x: hidden;
}
.data-cookie {
  color: white;
  text-decoration: underline;
  margin-left: 8px;
}
.data-cookie:hover {
  color: white;
}
.cookie-text {
  margin-right: 1rem;
}
.newsRow {
  display: flex;
  justify-content: center;
}
.container-xxl.home-slider {
  margin-top: -20rem;
}
.slick-slider.slick-initialized {
  padding-left: 0;
  padding-right: 0;
}
#first-row,
#second-row {
  background-size: cover;
  top: 0;
  left: 0;
  padding-bottom: 15rem;
  padding-top: 3.7rem;
  margin-top: -0.5rem;
}

.slick-slide img {
  padding: 0;
}

.home {
  margin-top: -60px;
}
