.card-body {
  border-radius: 0px 0px 10px 0px;
  padding: 20px 0 0 0;
}
.card {
  margin-bottom: 20px;
  background: transparent;
  border-radius: 10px;
}
.card-carousel {
  height: 355px;
  width: 100%;
}
.container-fluid {
  padding-right: 0px;
  padding-left: 0px;
}
.container-fluid.whatsNew .row {
  margin: unset;
}
.card-text {
  font-family: Montserrat;
}

.card-img-top {
  border-radius: 10px;
}
.news-date {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #e3e3e3;
  margin-bottom: 8px;
}

.news-short-desc {
  font-weight: 200;
  font-size: 14px;
  padding: 0px;
  margin: 0px;
  color: #e3e3e3;
  opacity: 0.8;
}

.news-desc {
  height: 75px;
  font-size: 16px;
}
.card .textContent {
  font-size: 16px;
  font-weight: 400;
}
.card .card-title {
  font-size: 20px;
  line-height: 24px;
  font-weight: 900 !important;
  margin-bottom: 8px;
  color: #fdfdfd;
}

/* .whatsNewCard {
  display: flex;
  flex-direction: row;
} */

.whatsNewCard img {
  border-radius: 10px;
  height: 240px;
  width: 100%;
}
.news-card-text {
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
}
.news-card-text img {
  margin-top: -13px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .newsRow {
    justify-content: flex-start !important;
  }
  /*.whatsNewCard img.card-img-top{*/
  /*    height: 170px !important;*/
  /*}*/

  .card-carousel {
    width: 75%;
    margin: 0px;
    padding: 0px;
  }
  .card .card-title {
    font-size: 16px;
  }
  .news-date {
    font-size: 14px;
  }
}
@media only screen and (min-width: 767px) and (max-width: 991px) {
  .news-card-text {
    margin-left: 1rem;
  }
  .newsRow {
    display: block;
  }
  .card .card-title {
    font-size: 16px;
  }
  .card-title {
    height: 50%;
  }
  .news-date {
    font-size: 14px;
  }
  .card-img-top.home-img-top {
    width: 100%;
  }
}
@media only screen and (min-width: 360px) and (max-width: 767px) {
  .news-card-text {
    margin-left: 1rem !important;
  }

  .news-short-desc {
    display: none;
  }
  /*.card-body{*/
  /*    width:50% !important;*/
  /*}*/

  .card-title {
    height: 50%;
  }
  .card .card-title {
    font-size: 16px;
  }
  .news-date {
    font-size: 14px;
  }
  .card-img-top.home-img-top {
    width: 100% !important;
  }
  .media-tab.nav-link {
    margin: 0;
  }
  .card-carousel {
    width: 90%;
    margin-left: 5%;
  }
}
.media-tab.nav-link {
  padding: unset !important;
  border-bottom-color: transparent !important;
}
