@font-face {
  font-family: Montserrat-Regular;
  src: url(/public/fonts/Montserrat-Regular.woff) format("truetype");
}

.drcr {
  font-family: Montserrat-Regular;
  font-size: 14px;
  height: 35px;
  color: white;
  background: #0058be;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 99;
  top: 0;
  width: 100%;
}

a {
  text-decoration: none;
  color: white;
  margin: 0;
}

#container-drcr {
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
}

@media only screen and (max-width: 991px) {
  .drcr {
    height: 40px;
    padding: 5px;
    font-size: 12px;
  }
}
