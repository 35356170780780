@font-face {
  font-family: Montserrat-Regular;
  src: url(/public/fonts/Montserrat-Regular.woff) format("truetype");
}
@font-face {
  font-family: Montserrat-Black;
  src: url(/public/fonts/Montserrat-Black.woff) format("truetype");
}
@font-face {
  font-family: Montserrat-c;
  src: url(/public/fonts/Montserrat-Bold.woff) format("truetype");
}
.container-xxl.contact::before {
  content: "";
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url("/public/images/contact-us-hero.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top right;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.banner-text-contact {
  font-family: "Montserrat-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: white;
}
.banner-title-contact {
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  display: flex;
  align-items: center;
  color: white;
}
.contact-title {
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  display: flex;
  align-items: center;
  text-align: right;
  color: white;
}
.contact-buttons {
  font-family: "Montserrat";
  height: 50px;
  margin-bottom: 25px;
  width: 702px;
  height: 50px;
  color: white;
  background: #191a1f;
  border: 2px solid #757575;
  box-sizing: border-box;
  border-radius: 10px;
}
.submit-button {
  background-color: white;
  border-radius: 10px;
  width: 256px;
  height: 50px;
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  justify-content: flex-end;
}
.social-media-title {
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  color: white;
  display: flex;
  align-items: flex-start;
}
.social-divider {
  width: 90%;
  height: 2px;
  background: #191a1f;
  border-top: 2px solid #ffffff;
  box-sizing: border-box;
  border-radius: 10px;
  margin-top: 1rem;
}
.social-media-accounts {
  display: flex;
  color: white;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin-top: 0.75rem;
}
#locations {
  margin-top: 3rem;
}
.social-media-accounts img {
  width: 24px;
  height: 24px;
}
.accounts {
  margin-left: 2rem;
  text-decoration: none;
  color: white;
}
.social-form {
  flex-direction: column;
}
.social-media-form {
  margin-top: 2rem;
}
@media only screen and (max-width: 991px) {
  .contact-buttons {
    width: 100%;
  }
  .submit-button {
    width: 100%;
  }
  .social-media-accounts {
    margin-bottom: 1rem;
  }
  .col-md-8.contact-form {
    display: flex;
    justify-content: center;
  }
  span {
    width: 100%;
  }
  form {
    display: flex;
    flex-direction: column;
    width: 90%;
  }
  .btn-group-vertical {
    width: 100%;
  }
}
#contact-banner {
  margin-bottom: 25rem;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .col-md-8.contact-form,
  .contact-buttons,
  .submit-button-span {
    width: 500px;
    margin-right: 3rem;
  }
}
@media only screen and (max-width: 768px) {
  #reach-us-form {
    margin-top: 3rem;
  }
  .submit-button,
  .submit-button-span {
    margin: auto;
  }
  .social-form,
  .submit-button-span {
    width: 100%;
    margin-left: 1rem;
  }
  .contact-buttons,
  .contact-title,
  #reach-us-form {
    margin-left: 1rem;
  }
  span {
    width: 100%;
  }
  .col-md-4.social-form {
    margin-bottom: 1.5rem;
  }
}
@media (min-width: 572px) and (max-width: 768px) {
  #reach-us-form {
    margin-left: 3rem;
  }
  .col-md-4.social-form {
    width: 270px;
    margin-left: 1rem;
  }
  .col-md-8.contact-form {
    width: 100%;
  }
}
@media (max-width: 572px) {
  .col-md-8.contact-form {
    width: 100%;
  }
}
input,
select {
  text-indent: 20px;
  opacity: 1;
}
input::placeholder {
  margin-left: 1rem;
  color: white;
  padding-top: 2px;
}
input:focus::placeholder {
  color: transparent;
}
select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  overflow: hidden;
  overflow: -moz-hidden-unscrollable;
}
.contact-buttons.select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  background-position: calc(100% - 12px) center !important;
  background-image: url("/public/images/dropdown-icon.svg");
  background-repeat: no-repeat;
}
textarea::placeholder {
  color: white;
  text-indent: 20px;
}
textarea:focus::placeholder {
  opacity: 0;
}
#message::placeholder {
  padding-top: 7px;
}
#message {
  height: 120px;
}
.submit-message {
  margin-top: 2rem;
  font-family: Montserrat-Regular;
  font-size: 20px;
  text-align: center;
  color: white;
}
.submit-icon {
  width: 100px;
  margin: auto;
}
