#footer {
  color: white;
}
p {
  margin-top: 0;
}
a {
  color: white;
  text-decoration: none;
}
a:hover {
  color: white;
  text-decoration: none;
}
#lower-text {
  font-family: "Montserrat-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  text-align: left;
  margin-top: 1.5rem;
}
.swifty-footer-divider {
  border-top: 1px solid #929292;
  transform: rotate(-0.1deg);
}
#upper-text {
  font-family: "Montserrat-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  align-items: center;
  display: flex;
}
#upper-text a {
  margin-left: 5px;
}
#social-icons {
  display: flex;
  justify-content: flex-end;
}
#social-icons img {
  padding-left: 15px;
  margin-bottom: 1rem;
}
#lower-text-right {
  text-align: right;
  font-family: "Montserrat-Regular";
  margin-top: 1.5rem;
}
@media only screen and (max-width: 991px) {
  #footer {
    width: 90%;
    margin-top: -3rem;
    margin-bottom: 2rem;
  }

  #social-icons {
    text-align: right;
    /* justify-content: center; */
  }
  #upper-text {
    text-align: center;
    font-size: 12px;
  }
  #upper-text a {
    margin-bottom: 1.5rem;
  }
  #lower-text-right {
    font-size: 12px;
    /* text-align: center; */
  }
  #lower-text,
  .upper-text {
    font-size: 12px;
    /* text-align: center; */
  }
  .swifty-footer-divider {
    transform: matrix(1, -0.01, 0, 1, 0, 0);
  }
}
@media only screen and (max-width: 768px) {
  #social-icons {
    text-align: center;
    justify-content: center;
  }
  #upper-text {
    text-align: center;
    justify-content: center;
  }
  #lower-text-right {
    font-size: 12px;
    text-align: center;
  }
  #lower-text,
  .upper-text {
    font-size: 12px;
    text-align: center;
  }
  #lower-text {
    margin-bottom: 10px;
  }
}
/*@media (min-width: 768px) and (max-width: 1024px) {*/
/*    #upper-text{*/
/*        margin: auto;*/
/*    }*/

/*    .col-md-6 .swifty-left {*/
/*        display: inline-block;*/
/*    }*/

/*    .col-md-6 .swifty-right {*/
/*        display: inline-block;*/

/*    }*/

/*    #lower-text {*/
/*        display: inline-block;*/
/*    }*/
/*}*/
