@font-face {
  font-family: Montserrat-Regular;
  src: url(/public/fonts/Montserrat-Regular.woff) format("truetype");
}
@font-face {
  font-family: Montserrat-Black;
  src: url(/public/fonts/Montserrat-Black.woff) format("truetype");
}
@font-face {
  font-family: Montserrat-Bold;
  src: url(/public/fonts/Montserrat-Bold.woff) format("truetype");
}
.container-xxl.whats-new::before {
  content: "";
  background-image: url("/public/images/latest-news-hero.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top right;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.banner-txt {
  font-family: "Montserrat-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: white;
  padding-bottom: 10px;
}
.banner-title {
  padding-bottom: 10px;
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  display: flex;
  align-items: center;
  color: white;
}
.read-button-gradient {
  background: linear-gradient(
    to right,
    rgba(255, 0, 95, 1),
    rgba(16, 159, 242, 1)
  );
  height: 42px;
  padding-top: 2px;
  border-radius: 10px;
  padding-left: 1px;
  width: 152px;
}
.read-button {
  font-family: "Montserrat-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  width: 150px;
  height: 38px;
  color: white;
  background: #191a1f;
  border-radius: 10px;
  -webkit-appearance: none;
  border: 0;
  box-shadow: 0;
}
.filter-title {
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  display: flex;
  align-items: center;
  text-align: right;
  color: white;
}
.filter-buttons {
  font-family: Montserrat;
  height: 50px;
  margin-bottom: 25px;
  min-width: 200px;
  max-width: 250px;
  color: white;
  background: #191a1f;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  border-radius: 10px;
}
#latest-news {
  margin-bottom: 2rem;
}
.load-more-ctr {
  width: 250px;
  margin-left: auto;
  margin-right: auto;
}
.load-more-btn {
  width: 250px;
  height: 50px;
  background: #ffffff;
  border-radius: 10px;
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin-top: -1rem;
}

.news-card-text {
  color: #fff;
  font-family: "Montserrat-Medium";
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 40px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-left: 1rem;
}
a.youtube-link {
  text-decoration: none;
  color: white;
  margin-left: 15px;
}
a:hover.youtube-link {
  text-decoration: none;
  color: white;
}
.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #3d4044;
  color: #2b2d30;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.5s;
  /*margin-left: 7rem;*/
  margin: auto;
}
.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #3d4044;
  color: #2b2d30;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}
.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #3d4044;
  color: #2b2d30;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}
@keyframes dotFlashing {
  0% {
    background-color: #3d4044;
  }
  50%,
  100% {
    background-color: #ebe6ff;
  }
}
@media only screen and (max-width: 1200px) {
  .news-card-text {
    margin-left: unset;
  }
}
@media only screen and (max-width: 992px) {
  .load-more-ctr {
    margin-top: 1rem;
  }
}
