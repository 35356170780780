@font-face {
  font-family: Montserrat-Bold;
  src: url(/public/fonts/Montserrat-Bold.woff) format("truetype");
}
@font-face {
  font-family: Montserrat-Black;
  src: url(/public/fonts/Montserrat-Black.woff) format("truetype");
}
@font-face {
  font-family: Montserrat-Medium;
  src: url(/public/fonts/Montserrat-Medium.woff) format("truetype");
}
a {
  color: transparent;
  text-decoration: none;
  margin-left: -0.5rem;
}
.link-light {
  text-decoration: underline 1px;
  height: 25px;
  width: 89px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: right;
}
.home-latest-news {
  margin: 10rem 0 1rem 0;
}

.card-img-top {
  border-radius: 10px 10px 0px 0px;
}
.col-md-4 {
  display: flex;
}
.container-fluid.whatsNew .newsRow {
  flex: 1 1 100%;
  margin-bottom: 1rem;
  padding: unset !important;
}
.container-fluid.whatsNew .newsRow .card-img-top {
  /* border-radius: 0; */
  width: 40%;
}
.container-fluid.whatsNew .newsRow .card-body {
  width: 50%;
  border-radius: 0;
  min-height: 200px;
}

.container-fluid.whatsNew .newsRow .whatsNewCard {
  width: 100%;
  display: flex;
  border-radius: 10px;
  padding: unset !important;
}

.filter-icon {
  display: flex;
  justify-content: right;
  align-items: end;
}
.row {
  margin: 13rem 0 1rem 0;
}
.slick-dots li button {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #c4c4c4;
}
.slick-dots li button:before {
  content: "";
  width: 10px;
  height: 10px;
  opacity: 0.5;
}
li.slick-active button:before {
  width: 20px;
  height: 10px;
  background: #4d94ff;
  border-radius: 10px;
}
.slick-dots li button:after {
  background-color: #4d94ff;
}

li.slick-active button {
  background-color: transparent;
  width: 20px;
  height: 10px;
}
.slick-dots li {
  width: 10px;
}
.slick-dots li.slick-active {
  width: 20px;
}

@media only screen and (max-width: 991px) {
  .home-latest-news {
    margin: 0;
  }
  .card-img-top {
    width: 50%;
    height: 50%;
  }
  .card-img-top.home-img-top {
    width: 100% !important;
  }
}
