@font-face {
  font-family: Montserrat-Regular;
  src: url(/public/fonts/Montserrat-Regular.woff) format("truetype");
}
@font-face {
  font-family: Montserrat-Black;
  src: url(/public/fonts/Montserrat-Black.woff) format("truetype");
}

.navbar-brand {
  display: flex;
  background-image: url("/public/images/logo.svg");
  width: 123px;
  height: 36px;
  margin: 0;
}

.container-fluid {
  padding-left: 0;
  overflow-x: hidden;
  overflow-y: hidden;
}

.navbar-sticky {
  top: 0;
  position: sticky;
  background: #000b17;
}

.nav-link {
  font-family: Montserrat-Regular;
  font-size: 18px;
  color: white !important;
  margin: 0 16px;
  border-bottom: transparent 2px solid;
}

.nav-link.active {
  border-bottom: white 2px solid;
}
.nav-link:hover {
  border-bottom: white 2px solid;
}
.nav-link:active {
  border-bottom: white 2px solid;
}
@media only screen and (max-width: 991px) {
  .navbar-collapse {
    background-color: #000b17;
    width: 100%;
    height: 100vh;
  }
  .navbar-brand {
    margin-left: 1rem;
  }
  .navbar-toggler {
    margin-right: 0.5rem;
    border: none;
    margin-top: -5px;
  }
  .btn-close .btn-close-white {
    opacity: 1;
  }
  #mobileNav {
    background-color: #000b17;
    padding-top: 0%;
  }
  .nav-link {
    border-bottom: #c4c4c4 2px solid !important;
    color: #ffffff !important;
    font-style: normal;
    font-weight: 400;
    display: flex;
    align-items: center;
    font-size: 16px;
  }
  a:is(:link, :active, :visited) .active {
    border-bottom: #c4c4c4 2px solid;
  }
  .container,
  .container-fluid,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    padding: 0;
    margin: auto;
    max-width: 991px;
  }
  #menuTitle {
    font-family: Montserrat-Black;
    font-size: 24px;
    display: flex;
    font-style: normal;
    font-weight: 400;
    color: #ffffff;
    padding-top: 2rem;
    padding-bottom: 1.7rem;
    margin-left: 16px;
    opacity: 1;
  }
  .btn-close,
  .btn-close-white {
    color: #ffffff !important;
    background-image: url("/public/images/close-icon.svg");
    opacity: 1;
    filter: none;
  }
  .navbar-dark .navbar-toggler-icon {
    background-image: url("/public/images/menu-icon.svg");
  }
  .navbar-dark .navbar-toggler {
    border: none;
    color: transparent;
  }

  /* #navbar-size {
    max-width: 1300px;
    position: relative;
    position: fixed;
    z-index: 99;
    top: 40px;
  } */
}

#navbar-size {
  max-width: 1300px;
  position: relative;
  z-index: 99;
}
