container-xxl.blog-page {
  overflow: hidden;
}
.background-image {
  background: linear-gradient(0.93deg, #000b17 -0.17%, rgba(0, 0, 0, 0) 47%),
    url("/public/images/body_background2.svg");
  background-size: auto;
  background-repeat: no-repeat;
  background-size: 100%;
}
.blog-page-container {
  height: 100%;
  margin-top: -60px;
}

.blog-page {
  height: 100%;
  margin-top: 92px;
  /*max-height: 100vh;*/
  z-index: -1;
}
.articleContent {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}
.article-header {
  margin-left: 8rem;
}
.article-title {
  font-family: "Montserrat" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 40px;
  color: #ffffff;
  padding-top: 12px;
  text-align: center;
}
.article-img {
  display: flex;
  margin: auto;
  width: 700px;
}
.article-date {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  text-align: center;
}
.article-text {
  margin-left: 8rem;
  margin-top: 3rem;
  font-family: Montserrat-Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  align-content: center;
}
.articleContent {
  margin-top: 2rem;
  font-family: Montserrat-Regular;
  color: white;
  text-align: left;
}

.article-description {
  font-family: Montserrat;
  padding: 60px 40px 0 40px;
  text-align: justify;
  color: #e4e8ee;
  line-height: 30px;
  font-weight: 600;
  font-size: 18px;
}

.article-image {
  position: relative;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 60px;
}
.article-image img {
  display: block;
  width: 100%;
  height: 450px;
}
.article-video {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  margin-top: 60px;
  margin-bottom: 20px;
}
.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.latest-news-blog-page {
  margin-top: 90px;
}

@media only screen and (max-width: 991px) {
  .latest-news-blog-page {
    display: none;
  }
  .article-text {
    margin-left: 1rem;
    margin-right: 1rem;
    font-size: 16px !important;
    line-height: 20px !important;
  }
  .article-header {
    margin-left: unset !important;
  }
  .article-date {
    margin-left: 1rem;
  }
  .article-description {
    margin-left: 1rem;
    margin-right: 1rem;
    font-size: 14px;
    padding-top: 40px;
    padding: 0;
  }
  .article-title {
    margin-left: 10px;
    margin-right: 10px;
    font-size: 24px !important;
    line-height: 30px !important;
  }
  .article-img {
    width: 360px !important;
  }

  .article-image img {
    padding: 0 10px;
    height: 300px;
  }

  .background-image {
    background-size: auto !important;
  }
  .back-icon {
    padding-left: 10px;
  }
}

.back-icon {
  color: #ffffff;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.back-icon p {
  margin: 0;
}
