@font-face {
  font-family: Montserrat-Regular;
  src: url(/public/fonts/Montserrat-Regular.woff) format("truetype");
}
@font-face {
  font-family: Montserrat-Bold;
  src: url(/public/fonts/Montserrat-Bold.woff) format("truetype");
}
.container-xxl.who-we-are::before {
  content: "";
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url("/public/images/who-we-are-hero.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top right;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.banner-text-who {
  font-family: "Montserrat-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: white;
}
.banner-title-who {
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  display: flex;
  align-items: center;
  color: white;
}
.col-md-6.who-we-are-banner {
  width: 100%;
}
.name-title {
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #ffffff;
  margin-top: 2rem;
}
.col-md-6 img {
  display: flex;
  justify-content: center;
  align-items: center;
}
.swifty-history-text {
  margin-top: 3rem;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
}
.linkedin-icon {
  margin: auto;
}
@media only screen and (max-width: 991px) {
  .swifty-history-text {
    text-align: justify;
    margin-left: unset;
  }
  .ceo-chairman-img {
    width: 100%;
    margin-top: 2rem;
  }
}
@media only screen and (max-width: 1200px) {
  .ceo-chairman-img {
    width: 100%;
  }
}
@media only screen and (min-width: 1200px) {
  .ceo-chairman-img {
    width: 95%;
  }
}
