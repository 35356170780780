.gradient-custom {
    background-color: whitesmoke;
    height: 100vh;
 }
 .card-body.bg-light.text-center{
     background-color: white!important;
     -webkit-box-shadow:  0 1px 25px rgba(116, 112, 112, 0.175);
     box-shadow: 0 1px 25px rgba(116, 112, 112, 0.175);
     flex: 1 1 auto;
     padding: 2rem;
     padding-top: 2rem;
     border-radius: 0.25rem;
 }
   
 .card.bg-dark.text-dark{
    border-radius: 2rem;
    margin-top: -10rem;
    border: none;
 }
 
 .btn.btn-md.px-5{
    background-color: #5380f6;
 }
 .btn{
    color: #ffff;
 }
 .welcome-text{
    color: grey;
    font-size: 20px;
 }
 .swifty-logo{
    width: 105px;
 }
 .form-check{
    display: block;
    
 }
 .form-check,.form-label{
    position: relative;
    text-align: left!important;
    display: flex;
 }
 .form-check-label{
    margin-left: 0.5rem;
 }
 .form-control{
    color: #5b626b;
 }

.form-control{
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: .8125rem;
    font-weight: 400;
    line-height: 1.5;
    color: #5b626b;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    margin: 0;
    text-indent: 0;
    opacity: 1;
}
.copyright.mt-5.text-center{
    font-size: 13px;
}
.forgot-password.col-12.mt-4{
    justify-content: flex-start;
    display: flex;
    font-size: 14px;
    margin-top: 0!important;
}
.form-group.mb-0.row{
    margin: 0;
    color: #6c757d!important;
}
.remember-me.form-check-label{
font-size: 14px;
}
