.pagination-container {
  gap: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  position: relative;
  border-top: 1px solid #1e2731;
  padding-top: 20px;
  flex-wrap: wrap; /* Allows wrapping of pagination items on smaller screens */
}

.pagination-active {
  background-color: #1f242f;
  border-radius: 8px;
}

.pagination-item {
  width: 40px;
  height: 40px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #94969c;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.pagination-item a {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination-item:hover {
  background-color: #fff;
  color: #000 !important;
  border-radius: 8px;
}

.previous {
  position: absolute;
  left: 0;
}

.next {
  position: absolute;
  right: 0;
}

.next a,
.previous a {
  color: #94969c !important;
}

.icons {
  gap: 10px;
  display: flex;
  align-items: center;
}

/* Responsive styles */
@media (max-width: 768px) {
  .pagination-container {
    gap: 10px;
    padding-top: 15px;
  }

  .pagination-item {
    width: 35px;
    height: 35px;
    font-size: 12px;
  }

  .icons {
    font-size: 12px;
  }

  .previous {
    position: static;
    order: -1; /* Moves it to the first item in smaller screens */
    margin-right: auto;
  }

  .next {
    position: static;
    margin-left: auto;
  }
}

@media (max-width: 480px) {
  .pagination-container {
    gap: 5px;
  }

  .pagination-item {
    width: 30px;
    height: 30px;
    font-size: 10px;
  }

  .icons {
    gap: 5px;
    font-size: 10px;
  }
}
